import { useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
import { UserInfo } from "@microsoft/teamsfx";
import {
  Field,
  Textarea,
  SpinButton,
  Button,
  MessageBar,
  MessageBarBody,
  Spinner,
  ToggleButton,
} from "@fluentui/react-components";
import { Image, Tooltip } from "@fluentui/react-components";
import { Combobox, Option, useId } from "@fluentui/react-components";
import type {
  OptionOnSelectData,
  SelectionEvents,
  SpinButtonChangeEvent,
  SpinButtonOnChangeData,
  SpinButtonProps,
  TextareaOnChangeData,
} from "@fluentui/react-components";

import * as bot from "../../api/bot.service";
import {
  CompanyValue,
  CreateNomination,
  SubscriptionDetails,
  TeamMemberOption,
} from "../../types/api-types";

import "./index.css";

interface data {
  userInfo: UserInfo;
  teamsSubscription: SubscriptionDetails;
}

const Form = (data: data) => {
  const fieldIdNominee = useId("combo-default");
  const fieldIdMessage = useId("message-default");
  const fieldIdPoints = useId("points-default");

  const nominatorId = data.userInfo.objectId;
  const groupId = data.userInfo.tenantId;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [nomineeOptions, setNomineeOptions] = useState<TeamMemberOption[]>([]);
  const [companyValues, setCompanyValues] = useState<CompanyValue[]>([]);
  const [selectedCompanyValues, setSelectedCompanyValues] = useState<string[]>(
    []
  );
  const [pointsBalance, setPointsBalance] = useState<number>(
    data.teamsSubscription.pointsBalance
  );
  const [nomineeId, setNomineeId] = useState<string | undefined>();
  const [points, setPoints] = useState<number | null>(0);
  const [message, setMessage] = useState<string | undefined>();
  const [channelLink, setChannelLink] = useState<string | undefined>();

  const onNomineeChange = useCallback(
    (_ev: SelectionEvents, data: OptionOnSelectData) => {
      if (data.optionValue !== undefined) {
        setNomineeId(data.optionValue);
      }
    },
    [setNomineeId]
  );

  const onPointsChange: SpinButtonProps["onChange"] = useCallback(
    (_ev: SpinButtonChangeEvent, data: SpinButtonOnChangeData) => {
      if (data.value !== undefined) {
        setPoints(data.value);
      } else if (data.displayValue !== undefined) {
        const newValue = parseFloat(data.displayValue);
        if (!Number.isNaN(newValue)) {
          setPoints(newValue);
        } else {
          console.error(`Cannot parse "${data.displayValue}" as a number.`);
        }
      }
    },
    [setPoints]
  );

  const onMessageChange = useCallback(
    (
      _ev: React.ChangeEvent<HTMLTextAreaElement>,
      data: TextareaOnChangeData
    ) => {
      if (data.value !== undefined) {
        setMessage(data.value);
      } else {
        console.error(`Cannot parse "${data.value}" as a text.`);
      }
    },
    [setMessage]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingOptions(true);
        const { tenantId } = data.userInfo;

        const results = await bot.getTeamMembers(tenantId);
        const members: TeamMemberOption[] = results.data;
        setNomineeOptions(members);
        const companyValuesResponse = await bot.getCompanyValues(tenantId);
        setCompanyValues(companyValuesResponse.data.data);
      } catch (error) {
        setIsLoadingOptions(false);
      } finally {
        setIsLoadingOptions(false);
      }
    };

    fetchData();
  }, [
    setNomineeOptions,
    setIsLoadingOptions,
    setCompanyValues,
    data,
    microsoftTeams,
  ]);

  const TooltipText = () => (
    <ul className="form-tooltip">
      <li>Enjoy a monthly boost of 100 credits</li>
      <li>Replenished on the 1st of each month</li>
      <li>Unused credits do not expire</li>
    </ul>
  );

  const redirectUser = async () => {
    const { tenantId } = data.userInfo;
    const teamsBotCredentialResults = await bot.getTeamsBotCredential(tenantId);
    const teamsData = teamsBotCredentialResults.data;
    const channelId = teamsData?.channelId;
    const channelName = teamsData?.channelName;
    const url = `https://teams.microsoft.com/l/channel/${channelId}/${channelName}`;
    setChannelLink(url);

    setSuccessMessage(
      `Your nomination has been recorded successfully! View this nomination by navigating to the Post tab within the ${channelName} channel.`
    );
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    const payload: CreateNomination = {
      nominatorId,
      groupId,
      nomineeId: nomineeId!,
      points: points!,
      message: message!,
      companyValues: selectedCompanyValues,
    };

    if (nominatorId === nomineeId) {
      setErrorMessage("You cannot nominate yourself");
      setIsLoading(false);
      return;
    }
    if (points! > pointsBalance) {
      setErrorMessage("You can't reward more than the points you have.");
      setIsLoading(false);
      setPoints(null);
      return;
    }

    try {
      await bot.submitRecognition(payload);
      // updating points balance
      const newPointsBalance = pointsBalance - points!;
      setPointsBalance(newPointsBalance);

      // resetting fields
      setNomineeId(undefined);
      setPoints(null);
      setMessage(undefined);
      setIsLoading(false);
      redirectUser();
    } catch (e: any) {
      setIsLoading(false);
      setErrorMessage(e.response.data.message);
      return;
    }
  };

  const ValueButton = (companyValue: CompanyValue) => {
    const onCompanyValueSelection = (companyValueId: string) => {
      const values = selectedCompanyValues;
      const index = values.indexOf(companyValueId);
      if (index >= 0) {
        values.splice(index, 1);
      } else {
        values.push(companyValueId);
      }
      setSelectedCompanyValues(values);
    };

    const [checked, setChecked] = useState(
      selectedCompanyValues.includes(companyValue.id)
    );

    return (
      <ToggleButton
        checked={checked}
        shape="circular"
        className={checked ? "company-value-selected" : "company-value"}
        onClick={() => {
          setChecked(!checked);
          onCompanyValueSelection(companyValue.id);
        }}
        key={`value-${companyValue.id}`}
      >
        {companyValue.value}
      </ToggleButton>
    );
  };

  return (
    <div>
      <h1>Nominate your peer</h1>
      <h3 className="credits-available">
        <div>
          You have <strong className="mint">{pointsBalance}</strong> credits
          available.
        </div>
        <Tooltip content={<TooltipText />} relationship="description">
          <Image src="tooltip.svg" />
        </Tooltip>
      </h3>

      {pointsBalance === 0 && (
        <MessageBar key="success" intent="success" style={{ marginBottom: 24 }}>
          <MessageBarBody>
            It looks like you have already spent all of your available points.
            Don't worry, we will top up your points at the beginning of the new
            month.
          </MessageBarBody>
        </MessageBar>
      )}

      {pointsBalance > 0 && (
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: 24 }}>
            <label id={fieldIdNominee}>Nomination Recipient</label>
            <Combobox
              aria-labelledby={fieldIdNominee}
              placeholder="Select a colleague"
              onOptionSelect={onNomineeChange}
              style={{ display: "grid" }}
            >
              {isLoadingOptions && (
                <Spinner appearance="primary" style={{ marginLeft: 12 }} />
              )}
              {nomineeOptions.map((option: any) => (
                <Option text={option.value} value={option.key}>
                  {option.value}
                </Option>
              ))}
            </Combobox>
            <small>Start typing a name to see dropdown list.</small>
          </div>
          <div style={{ marginBottom: 24 }}>
            <label id={fieldIdPoints}>Please enter a credit amount</label>
            <Field>
              <SpinButton
                precision={0}
                value={points}
                onChange={onPointsChange}
                max={pointsBalance}
              />
            </Field>
          </div>
          {companyValues && companyValues.length > 0 && (
            <div style={{ marginBottom: 24 }}>
              <label id={fieldIdPoints} style={{ display: "block" }}>
                Choose any company values that apply to this nomination
                (Optional)
              </label>
              {companyValues.map((companyValue) => {
                return <ValueButton {...companyValue} />;
              })}
            </div>
          )}
          <div style={{ marginBottom: 24 }}>
            <label id={fieldIdMessage}>Please enter your message</label>
            <Field size="large">
              <Textarea
                placeholder="type here..."
                value={message}
                onChange={onMessageChange}
              />
            </Field>
          </div>
          {errorMessage && (
            <MessageBar key="error" intent="error" style={{ marginBottom: 24 }}>
              <MessageBarBody>{errorMessage}</MessageBarBody>
            </MessageBar>
          )}
          {successMessage && (
            <>
              <MessageBar
                key="success"
                intent="success"
                style={{ marginBottom: 24 }}
              >
                <MessageBarBody>{successMessage}</MessageBarBody>
              </MessageBar>
            </>
          )}
          <div style={{ display: "flex" }}>
            <Button type="submit" className="btn-mint" disabled={isLoading}>
              Submit
            </Button>
            {isLoading && (
              <Spinner appearance="primary" style={{ marginLeft: 12 }} />
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Form;
