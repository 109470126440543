import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";

import {
  TeamsBotCredentials,
  TeamsBotCredentialUpdateResponse,
} from "../../types/api-types";

import * as bot from "../../api/bot.service";

interface iDisconnectBtn {
  showDisconnectBtn: boolean;
  teamsBotCredentials: TeamsBotCredentialUpdateResponse;
}

const DisconnectBtn = ({
  showDisconnectBtn,
  teamsBotCredentials,
}: iDisconnectBtn) => {
  // resetting teamsBotCredentials by clearing conversation ID and company ID
  const disconnectApp = async () => {
    const conversationId = null; // resetting integrator's user ID
    const companyId = null; // resetting integrator's company ID
    const teamsCredsId = teamsBotCredentials.id;
    const newCredentials: TeamsBotCredentials = {
      teamsAppId: teamsBotCredentials.teamsAppId,
      conversationId,
      channelId: teamsBotCredentials.channelId,
      channelName: teamsBotCredentials.channelName,
      companyId,
      accessToken: teamsBotCredentials.accessToken,
      tokenExpiry: teamsBotCredentials.tokenExpiry,
      tenantId: teamsBotCredentials.tenantId,
      teamId: teamsBotCredentials.teamId,
      teamworkTagId: null,
    };
    await bot.updateTeamsBotCredential(teamsCredsId, newCredentials);

    // reloading app so that it refreshes the state.
    window.location.reload();
  };

  if (!showDisconnectBtn) {
    return null;
  }
  if (showDisconnectBtn) {
    return (
      <Dialog>
        <DialogTrigger disableButtonEnhancement>
          <Button className="btn-mint-outline disconnect-btn">
            Disconnect Zuzo Account
          </Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Disconnect Zuzo Account</DialogTitle>
            <DialogContent>
              This will disconnect your Zuzo account from your Teams
              environment. You can always reconnect your account again.
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" className="btn-mint-outline">
                  Cancel
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="btn-mint"
                onClick={() => {
                  disconnectApp();
                }}
              >
                Disconnect
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }
};

export default DisconnectBtn;
